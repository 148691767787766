<template>
  <div class="w-full">
    <TitleWrapper
      class="mb-5"
      :filter-option="false"
      title="Profile"
      tooltip-title="PROFILE_TOOLTIP"
      :display-breadcrumb="true"
    />
    <div v-if="currentStudent?.first_name" class="px-7 pb-7 bg-white rounded-lg">
      <div v-for="(detail, key, index) in studentDetails" :key="index">
        <div class="font-medium font-inter text-xl text-label-text border-b border-primary-grey">
          <Heading :heading="key && toPascalCase(key)" />
        </div>
        <div>
          <div
            v-for="(subDetail, subKey, subIndex) in detail"
            :key="subIndex"
            class="mt-5 flex font-inter font-base mb-4"
          >
            <div class="w-40">{{ subKey && toPascalCase(subKey) }}:</div>
            <div class="text-menu">
              {{ subDetail || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <StudentModal
      v-if="toggleModal"
      :modal="toggleModal"
      :edit="currentStudent"
      @toggle="toggleStudent"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import StudentModal from '@router/views/admin/modals/AddStudentModal.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { toPascalCase } from '@src/utils/generalUtil.js'
import Heading from '@src/components/Heading.vue'
export default {
  components: {
    StudentModal,
    TitleWrapper,
    Heading,
  },
  data() {
    return {
      toggleModal: false,
      get currentStudent() {
        return JSON.parse(localStorage.getItem('currentActiveStudent'))
      },
      set currentStudent(value) {
        JSON.parse(localStorage.getItem('currentActiveStudent'))
      },

      forceReRender: 1,
      dashboard: 'dashboard',
      title: 'title',
      GENERAL_CONSTANTS,
    }
  },
  computed: {
    ...mapState({
      listenStorage: (state) => state.student.listenStorage,
      showModal: (state) => state.layout.showModal,
    }),
    studentDetails() {
      return {
        personalInfo: {
          name: `${this.currentStudent.first_name} ${this.currentStudent.last_name}`,
          gender: this.currentStudent.gender,
          bloodGroup: this.currentStudent?.profile?.blood_group,
          dateOfBirth: this.currentStudent?.profile?.date_of_birth,
          country: this.currentStudent?.identity_info?.country,
        },
        contactInfo: {
          phone: this.currentStudent.phone,
          email: this.currentStudent.email,
          adress: this.currentStudent?.profile?.address,
        },
        mainGuardianInfo: {
          guardianName: `${this.currentStudent?.student_info?.main_guardian?.first_name} ${this.currentStudent?.student_info?.main_guardian?.last_name}`,
          email: this.currentStudent?.student_info?.main_guardian?.email,
          relation: this.currentStudent?.student_info?.main_guardian?.relation,
          phone: this.currentStudent?.student_info?.main_guardian?.phone,
          profession: this.currentStudent?.student_info?.main_guardian?.profession,
        },
        academicInfo: {
          registrationNo: this.currentStudent?.student_info?.registration_no,
          rollNo: this.currentStudent?.student_info?.roll_no,
        },
      }
    },
  },

  watch: {
    showModal: {
      handler() {
        this.toggleModal = true
      },
    },
    listenStorage: {
      handler(value) {
        if (value) this.updateStudent()
      },
    },
  },
  created() {
    this.currentStudent = JSON.parse(localStorage.getItem('currentActiveStudent'))
    this.setRightbarData({})
  },
  methods: {
    toPascalCase,
    toggleStudent() {
      this.toggleModal = false
    },
    /**
     * Update student on profile page when change from leftbar
     */
    updateStudent() {
      this.currentStudent = JSON.parse(localStorage.getItem('currentActiveStudent'))
      this.setRightbarData()
      this.$store.commit('student/SET_LISTEN_STORAGE', false)
      localStorage.setItem('currentActiveStudent', JSON.stringify(this.currentStudent))
      this.forceReRender++
    },
    setRightbarData() {
      const rightBarContent = {
        header: {
          title: 'Profile Details',
          buttons: [
            {
              title: 'Edit Profile',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        profileInfo: [
          {
            imageUrl: this.currentStudent?.image || null,
            metaInfo: [
              {
                lastClass: '23/08/2020',
                attendance: '24',
                leave: '4',
                absent: '5',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>
